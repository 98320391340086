<template lang="pug">
.map
  transition(name="fade")
    .my-toaster(v-if="loading")
      .loading.loading--s30
        .loading__animation
          div
      | Загрузка...
  slot(v-if="map")
    transition(name="fade")
      div(v-if="dataLoading")
        Control
        Gallery(:images="imagesGallery")
  .map__container#map
</template>

<script>
import { useMap } from '@/js/use/map'
import 'mapbox-gl/dist/mapbox-gl.css'

import Control from '@/components/Control'
import Gallery from '@/components/Gallery'
import { ref } from 'vue'
import { createToaster } from '@meforma/vue-toaster'
import { store } from '@/store'
import { useRoute } from 'vue-router'

export default {
  components: {
    Control,
    Gallery
  },
  setup () {
    const route = useRoute()
    const toast = createToaster({ position: 'bottom-right' })
    const loading = ref(true)
    store.subscribe(mutation => {
      if (mutation.type === 'SOURCES_GET') {
        loading.value = true
      }
      if (mutation.type === 'SOURCES_SUCCESS' || mutation.type === 'SOURCES_ERROR') {
        loading.value = false
      }
      if (mutation.type === 'SOURCES_ERROR') {
        setTimeout(() => {
          toast.error('Ошибка загрузки данных')
        }, 300)
      }
    })
    const getOptionsMap = () => {
      if (route.query.lat && route.query.lng) {
        const { lng, lat, zoom } = route.query
        return {
          center: [lng, lat],
          zoom: zoom ?? 15
        }
      }
      return {
        bounds: [
          [85.559453, 55.262379],
          [86.171903, 55.540422]
        ]
      }
    }

    return {
      loading,
      ...useMap({ container: 'map', options: getOptionsMap() })
    }
  }
}
</script>
