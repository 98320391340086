<template lang="pug">
.mapControl__groupe.mapControl__groupe--list
  // Кнопка
  .mapControl__item(title="Слои" @click="toggleDataLayers" :class="{ active: dataLayers.open }")
    .mapControl__icon.mapControl__icon--layers
  // Список слоев 1го уровня
  .mapControlLayers(v-if="dataLayers.open" v-click-away="hideDataLayers")
    .mapControlLayers__item(
      v-for="(layer, index) in dataLayers.list"
      :key="index"
      :class="{ active: layer.visible }"
      @mouseover="openDataLayer(index, true)"
      @mouseleave="openDataLayer(index, false)"
    )
      slot(v-if="!layer.list || !layer.subLayersShow")
        .mapControlLayers__checkbox(@click="toggleLayer(index)")
        span(@click="toggleLayer(index)") {{ layer.name }}
        .mapControlLayers__marker(
          v-if="layer.icon"
          :style="{'background-image': 'url(' + layer.icon + ')'}"
        )
        svg( v-else viewBox="0 0 12 12" @click="toggleLayer(index)")
          path(d="M10.5 5.625C10.5 8.31739 8.31739 10.5 5.625 10.5C2.93261 10.5 0.75 8.31739 0.75 5.625C0.75 2.93261 2.93261 0.75 5.625 0.75C8.31739 0.75 10.5 2.93261 10.5 5.625Z" :fill="layer.color" :stroke="layer.color")
      slot(v-else)
        .mapControlLayers__checkbox(@click="toggleLayer(index)")
        span(@click="toggleLayer(index)") {{ layer.name }}
        svg(width="7" height="13" viewBox="0 0 7 13")
          path(fill-rule="evenodd" clip-rule="evenodd" d="M6.25926 6.90077C6.58025 6.52628 6.58025 5.97368 6.25926 5.59919L1.75926 0.349191C1.39983 -0.0701349 0.768534 -0.118697 0.349208 0.240726C-0.0701175 0.600148 -0.118679 1.23145 0.240743 1.65077L4.18292 6.24998L0.240743 10.8492C-0.118679 11.2685 -0.070117 11.8998 0.349209 12.2592C0.768535 12.6187 1.39983 12.5701 1.75926 12.1508L6.25926 6.90077Z")
        // Список слоев 2го уровня
        .mapControlLayers.mapControlLayers--secondary(v-if="layer.open")
          slot(
            v-for="(subLayer, j) in layer.list"
            :key="j"
          )
            .mapControlLayers__item(:class="{ active: subLayer.visible }")
              .mapControlLayers__checkbox(@click="toggleLayer(index, j)")
              span(@click="toggleLayer(index, j)") {{ subLayer.name }}
              .mapControlLayers__marker(
                v-if="subLayer.icon"
                :style="{'background-image': 'url(' + subLayer.icon + ')', 'background-color': subLayer.color}"
              )
              svg(v-else viewBox="0 0 12 12")
                path(d="M10.5 5.625C10.5 8.31739 8.31739 10.5 5.625 10.5C2.93261 10.5 0.75 8.31739 0.75 5.625C0.75 2.93261 2.93261 0.75 5.625 0.75C8.31739 0.75 10.5 2.93261 10.5 5.625Z" :fill="subLayer.color" :stroke="subLayer.color")
            .mapControlLayers__signature.signature(v-if="subLayer.signatures.length")
              .signature__item(v-for="(signature, indexS) in subLayer.signatures" :key="indexS")
                .signature__marker(
                  :class="'signature__marker--' + signature.type"
                  :style="{ 'border-color': signature.color }"
                )
                span {{ signature.name }}
</template>

<script>
import { useLayers } from '@/js/use/layers'

export default {
  setup () {
    return {
      ...useLayers()
    }
  }
}
</script>
