import { getBackLayers } from '@/js/map/_layers/back'
import { getProjectLayers } from '@/js/map/_layers/project'
import { getTopoLayers } from '@/js/map/_layers/topo'
import { getPointsInterest } from '@/js/map/_layers/points'

export async function layersList () {
  const preSources = {
    ortho1: {
      legend: {
        color: 'transparent',
        unitedLayer: {
          id: 'ortho',
          name: 'Ортофотоплан'
        }
      },
      options: {
        type: 'raster',
        tiles: [`${window.location.origin}/road-2021/tiles/1/{z}/{x}/{y}.png`],
        tileSize: 256,
        bounds: [85.5914161, 55.2827493, 85.7701162, 55.3940829],
        maxzoom: 20
      },
      layers: [
        {
          id: 'ortho1',
          type: 'raster',
          source: null
        }
      ]
    },
    ortho2: {
      legend: {
        color: 'transparent',
        unitedLayer: {
          id: 'ortho',
          name: 'Ортофотоплан'
        }
      },
      options: {
        type: 'raster',
        tiles: [`${window.location.origin}/road-2021/tiles/2/{z}/{x}/{y}.png`],
        tileSize: 256,
        bounds: [85.7260219478346, 55.3802381455574, 85.8454077996407, 55.4780625868369],
        maxzoom: 20
      },
      layers: [
        {
          id: 'ortho2',
          type: 'raster',
          source: null
        }
      ]
    },
    ortho3: {
      legend: {
        color: 'transparent',
        unitedLayer: {
          id: 'ortho',
          name: 'Ортофотоплан'
        }
      },
      options: {
        type: 'raster',
        tiles: [`${window.location.origin}/road-2021/tiles/3/{z}/{x}/{y}.png`],
        tileSize: 256,
        bounds: [85.8187542764042, 55.4613197166182, 85.9482259836347, 55.527834654114],
        maxzoom: 20
      },
      layers: [
        {
          id: 'ortho3',
          type: 'raster',
          source: null
        }
      ]
    },
    ortho4: {
      legend: {
        color: 'transparent',
        unitedLayer: {
          id: 'ortho',
          name: 'Ортофотоплан'
        }
      },
      options: {
        type: 'raster',
        tiles: [`${window.location.origin}/road-2021/tiles/4/{z}/{x}/{y}.png`],
        tileSize: 256,
        bounds: [85.9229791850464, 55.4498962307032, 86.1432185352154, 55.5357186712113],
        maxzoom: 20
      },
      layers: [
        {
          id: 'ortho4',
          type: 'raster',
          source: null
        }
      ]
    },
    mo: {
      legend: {
        name: 'Границы муниципальных образований',
        color: '#9ED726'
      },
      options: {
        type: 'geojson',
        data: './shapes/mo.geojson'
      },
      layers: [
        {
          id: 'mo_line',
          type: 'line',
          source: null,
          paint: {
            'line-color': '#9ED726',
            'line-width': 2
          }
        },
        {
          id: 'mo_symbol',
          type: 'symbol',
          source: null,
          paint: {
            'text-color': '#555',
            'text-halo-color': 'rgba(255, 255, 255, 0.6)',
            'text-halo-width': 1
          },
          layout: {
            'text-field': '{NAME}',
            'symbol-placement': 'line',
            'text-offset': [0, 1]
          }
        }
      ]
    }
  }

  const sources = Object.assign(
    getBackLayers(),
    preSources,
    getTopoLayers(),
    getProjectLayers(),
    getPointsInterest()
  )

  for (const nameSource in sources) {
    sources[nameSource].layers.map(layer => {
      layer.source = nameSource
      return layer
    })
    if (sources[nameSource].visible === 'none') {
      for (const i in sources[nameSource].layers) {
        if ('layout' in sources[nameSource].layers[i]) {
          // eslint-disable-next-line
          sources[nameSource].layers[i].layout['visibility'] = 'none'
        } else {
          // eslint-disable-next-line
          sources[nameSource].layers[i]['layout'] = { visibility: 'none' }
        }
      }
    }
  }
  return sources
}

const getVisibleLayer = layers => {
  const visibleLayers = layers.filter(layer => {
    if ('layout' in layer && 'visibility' in layer.layout) {
      return layer.layout.visibility !== 'none'
    } else {
      return true
    }
  })
  return !!visibleLayers.length
}

export const getLegendLayers = sources => {
  const legendLayers = []
  for (const nameSource in sources) {
    if (sources[nameSource].legend) {
      let indexUnitedLayer = null

      if (sources[nameSource].legend.unitedLayer) {
        const unitedLayer = sources[nameSource].legend.unitedLayer
        indexUnitedLayer = legendLayers.findIndex(layer => layer.id === unitedLayer.id)
        if (indexUnitedLayer === -1) {
          legendLayers.push({
            id: unitedLayer.id,
            name: unitedLayer.name,
            color: unitedLayer.color || 'transparent',
            subLayersShow: true,
            visible: null,
            open: false,
            list: []
          })
          indexUnitedLayer = legendLayers.findIndex(layer => layer.id === unitedLayer.id)
        }
      }
      const color = (sources[nameSource].legend.color)
        ? sources[nameSource].legend.color === 'none'
          ? 'transparent'
          : sources[nameSource].legend.color
        : sources[nameSource].layers.find(layer => layer.type === 'fill').paint['fill-color']
      const newLegendLayer = {
        id: nameSource,
        name: sources[nameSource].legend.name || '',
        layers: sources[nameSource].layers.map(layer => layer.id),
        visible: getVisibleLayer(sources[nameSource].layers),
        color,
        icon: sources[nameSource].legend.icon || false,
        signatures: sources[nameSource].legend.signatures || []
      }
      if (legendLayers[indexUnitedLayer]) {
        legendLayers[indexUnitedLayer].list.push(newLegendLayer)
      } else {
        legendLayers.push(newLegendLayer)
      }
    }
  }
  // видимость объедененных слоев
  legendLayers.forEach((layer, index) => {
    if (!('layers' in layer)) {
      legendLayers[index].visible = !!layer.list.filter(sublayer => sublayer.visible).length
    }
    if ('list' in layer) {
      legendLayers[index].subLayersShow = !!layer.list.filter(sublayer => sublayer.name).length
    }
  })
  return legendLayers
}
