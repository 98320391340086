import { createStore } from 'vuex'
import auth from './modules/auth'
import sources from './modules/sources'

const debug = process.env.NODE_ENV !== 'production'

export const store = createStore({
  modules: {
    auth,
    sources
  },
  strict: debug
})
