<template lang="pug">
.map__measure.mapMeasure(v-if="visible && Object.keys(measure).length")
  .mapMeasure__close(@click="toggle")
    svg(width="11" height="11" viewBox="0 0 11 11")
      path(d="M10 1.12763L1.07006 10M1 1L9.92994 9.87237")
  .mapMeasure__inner(v-if="measure.type === 'point'")
    .mapMeasure__row
      .mapMeasure__title Долгота:
      .mapMeasure__value {{ measure.values.lng }}
    .mapMeasure__row
      .mapMeasure__title Широта:
      .mapMeasure__value {{ measure.values.lat }}
  .mapMeasure__inner(v-else-if="measure.type === 'polygon'")
    .mapMeasure__row
      .mapMeasure__title Площадь:
      .mapMeasure__value(v-html="measure.values.s")
    .mapMeasure__row
      .mapMeasure__title Периметр:
      .mapMeasure__value {{ measure.values.p }}
  .mapMeasure__inner(v-else-if="measure.type === 'line'")
    .mapMeasure__row
      .mapMeasure__title Расстояние:
    .mapMeasure__row
      .mapMeasure__value {{ measure.values.l }}
</template>

<script>
import { useToggle } from '@/js/use/toggle'

export default {
  props: ['measure'],
  setup () {
    return {
      ...useToggle()
    }
  }
}
</script>
