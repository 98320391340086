import {
  SOURCES_GET,
  SOURCES_SET,
  SOURCES_SUCCESS,
  SOURCES_ERROR
} from '@/store/actions/sources'
import { layersList } from '@/js/map/layersList'

const state = {
  status: '',
  sources: null,
  legendLayers: null
}
const getters = {
  getSources: state => state.sources,
  sourcesStatus: state => state.status
}

const actions = {
  [SOURCES_GET]: async ({ commit }) => {
    commit(SOURCES_GET)
    try {
      const sources = await layersList()
      await commit(SOURCES_SET, { sources })
      commit(SOURCES_SUCCESS)
    } catch (err) {
      commit(SOURCES_ERROR)
    }
  }
}

const mutations = {
  [SOURCES_GET]: state => {
    state.status = 'loading'
  },
  [SOURCES_SET]: (state, { sources }) => {
    state.sources = sources
  },
  [SOURCES_ERROR]: state => {
    state.status = 'error'
  },
  [SOURCES_SUCCESS]: state => {
    state.status = 'success'
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
