import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { store } from './store'
import Toaster from '@meforma/vue-toaster'
import VueClickAway from 'vue3-click-away'
import VueEasyLightbox from 'vue-easy-lightbox'

const app = createApp(App)
app.use(router)
app.use(store)
app.use(Toaster)
app.use(VueClickAway)
app.use(VueEasyLightbox)
app.mount('#app')
