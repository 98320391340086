export const getBackLayers = () => {
  return {
    satellite: {
      options: {
        type: 'raster',
        tiles: ['https://mt0.google.com/vt/lyrs=y&hl=ru&x={x}&y={y}&z={z}'],
        tileSize: 256
      },
      layers: [
        {
          id: 'satellite',
          type: 'raster',
          source: null,
          layout: {
            visibility: 'none'
          }
        }
      ]
    }
  }
}
