import { inject, reactive } from 'vue'
import { getLegendLayers } from '@/js/map/layersList'
import { store } from '@/store'

export function useLayers () {
  const map = inject('map')
  const dataLayers = reactive({
    open: false,
    list: getLegendLayers(store.getters.getSources)
  })
  const toggleDataLayers = () => {
    dataLayers.open = !dataLayers.open
  }
  const hideDataLayers = (event) => {
    dataLayers.open = false
  }
  const toggleVisibleLayer = layer => {
    const visibility = layer.visible ? 'visible' : 'none'
    if ('list' in layer) {
      layer.list.forEach(unitedLayer => {
        const visibleSubLayer = unitedLayer.visible && layer.visible ? 'visible' : 'none'
        unitedLayer.layers.forEach(item => {
          map.value.setLayoutProperty(item, 'visibility', visibleSubLayer)
        })
      })
    } else {
      layer.layers.forEach(item => {
        map.value.setLayoutProperty(item, 'visibility', visibility)
      })
    }
  }
  const toggleLayer = (idLayer, idSubLayer = null) => {
    const layer = dataLayers.list[idLayer]
    if (idSubLayer !== null) {
      const subLayer = layer.list[idSubLayer]
      subLayer.visible = !subLayer.visible
      toggleVisibleLayer(subLayer)
      const count = !!layer.list.filter(item => item.visible).length
      if (count && !layer.visible) {
        dataLayers.list[idLayer].visible = true
        return
      }
      if (!count) {
        dataLayers.list[idLayer].visible = false
      }
      return
    }
    layer.visible = !layer.visible
    toggleVisibleLayer(layer)
  }

  const openDataLayer = (idLayer, open) => {
    const layer = dataLayers.list[idLayer]
    if (layer.list) {
      layer.open = open
    }
  }
  return {
    dataLayers, hideDataLayers, toggleDataLayers, toggleLayer, openDataLayer
  }
}
