export const getPointsInterest = () => {
  return {
    pointsInterest: {
      legend: {
        name: 'Точки интереса',
        color: '#2648BE'
      },
      options: {
        type: 'geojson',
        data: `${window.location.origin}/shapes/points.geojson`,
        generateId: true
      },
      layers: [
        {
          id: 'pi_circle',
          type: 'circle',
          source: null,
          paint: {
            'circle-color': '#2648BE',
            'circle-radius': [
              'interpolate',
              ['linear'],
              ['zoom'],
              10, 4,
              14, 20
            ],
            'circle-stroke-width': 2,
            'circle-stroke-color': ['case', ['boolean', ['feature-state', 'hover'], false], '#ccc', '#fff']
          },
          options: {
            hover: 'popup',
            popup: {
              text: '',
              coordinates: 'Координаты'
            } // first prop - title popup
          }
        },
        {
          id: 'pi_icons',
          type: 'symbol',
          source: null,
          layout: {
            'icon-allow-overlap': true,
            'icon-image': 'imageIcon',
            'icon-size': [
              'interpolate',
              ['linear'],
              ['zoom'],
              10, 0.01,
              14, 0.04
            ]
          },
          paint: {
            'icon-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 0.8, 1]
          },
          minzoom: 10
        }
      ]
    }
  }
}
