import axios from 'axios'

export const loginRequest = async ({ login, password }) => {
  try {
    const responseAuth = await fetch('/auth/csrf', {
      method: 'GET'
    })
    const { token } = await responseAuth.json()

    const responseLogin = await fetch('/auth/login', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        login,
        password,
        _token: token
      })
    })
    if (responseLogin.ok) {
      return {
        token
      }
    }
  } catch (err) {
    return err
  }
}

export const getUser = () => {
  return fetch('/auth/user')
    .then(response => response.json())
}

export const logoutRequest = () => {
  return fetch('/auth/logout')
}

export const routingCar = (coordinates) => {
  return axios({
    method: 'POST',
    url: 'https://api.openrouteservice.org/v2/directions/driving-car/geojson',
    data: {
      coordinates: coordinates,
      radiuses: [10000, 10000]
    },
    headers: {
      Authorization: '5b3ce3597851110001cf62486c53c0fd5c9442dbbbb9737306b8e3f6',
      Accept: 'application/json, application/geo+json, application/gpx+xml, img/png; charset=utf-8',
      'Content-Type': 'application/json; charset=utf-8'
    }
  }).then(response => response.data)
}
