const colors = (field) => {
  return [
    'match',
    ['get', field],
    'black', '#000',
    'blue', '#2648BE',
    'cyan', '#00E4D6',
    'darkgreen', '#00AB59',
    'green', '#9ED726',
    'purple', '#C92F8C',
    'red', '#FB4141',
    'yellow', '#FFFA7E',
    'brown', '#FF7D34',
    /* other */ '#fff'
  ]
}

export const getTopoLayers = () => {
  return {
    topo: {
      legend: {
        name: 'Топографический план',
        color: 'none'
      },
      // visible: 'none',
      options: {
        type: 'vector',
        tiles: [`${window.location.origin}/road-2021/vector/topo-z20/{z}/{x}/{y}.pbf?1`],
        minzoom: 0,
        maxzoom: 20,
        bounds: [85.600053, 55.313769, 86.135336, 55.526708]
      },
      layers: [
        {
          id: 't_lines_dashed',
          type: 'line',
          source: null,
          filter: ['==', 'ТИПЛИ', 'dashed'],
          'source-layer': 'lines',
          paint: {
            'line-color': colors('СЛОЙ'),
            'line-dasharray': [3, 3],
            'line-width': 1
          }
        },
        {
          id: 't_lines_dots',
          type: 'line',
          source: null,
          filter: ['==', 'ТИПЛИ', 'dots'],
          'source-layer': 'lines',
          paint: {
            'line-color': colors('СЛОЙ'),
            'line-dasharray': [1, 3],
            'line-width': 1
          }
        },
        {
          id: 't_lines_longdashed',
          type: 'line',
          source: null,
          filter: ['==', 'ТИПЛИ', 'longdashed'],
          'source-layer': 'lines',
          paint: {
            'line-color': colors('СЛОЙ'),
            'line-dasharray': [6, 6],
            'line-width': 1
          }
        },
        {
          id: 't_lines_shortdashed',
          type: 'line',
          source: null,
          filter: ['==', 'ТИПЛИ', 'shortdashed'],
          'source-layer': 'lines',
          paint: {
            'line-color': colors('СЛОЙ'),
            'line-dasharray': [4, 2],
            'line-width': 1
          }
        },
        {
          id: 't_lines_continuous',
          type: 'line',
          source: null,
          'source-layer': 'lines',
          filter: ['==', 'ТИПЛИ', 'Continuous'],
          paint: {
            'line-color': colors('СЛОЙ'),
            'line-width': 1
          }
        },
        {
          id: 't_points',
          type: 'symbol',
          source: null,
          'source-layer': 'points',
          paint: {
            'text-color': colors('Color'),
            'text-halo-color': 'rgba(255, 255, 255, 0.7)',
            'text-halo-width': 1
          },
          layout: {
            'text-field': '{TEXTSTRING}',
            'text-anchor': 'center',
            'text-justify': 'center',
            'text-max-width': 200,
            'text-size': ['*', ['get', 'zoom'], ['get', 'Size'], 0.3],
            'text-rotate': ['get', 'Angle']
          },
          minzoom: 16
        }
      ]
    }
  }
}
