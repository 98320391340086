<template lang="pug">
#app
  Header(v-if="isAuthenticated")
  router-view
</template>

<script>
import Header from '@/components/Header'
import { computed, onBeforeMount } from 'vue'
import { store } from '@/store'
import router from '@/router'
import { AUTH_LOGIN } from '@/store/actions/auth'

export default {
  components: {
    Header
  },
  setup () {
    const isAuthenticated = computed(() => store.getters.isAuthenticated)
    const authLogin = onBeforeMount(() => {
      if (store.getters.isAuthenticated) {
        store.dispatch(AUTH_LOGIN)
      }
      setInterval(() => {
        if (store.getters.isAuthenticated) {
          store.dispatch(AUTH_LOGIN).then().catch(err => {
            router.push('/login')
            console.error(err)
          })
        }
      }, 600_000)
    })
    const unsubscribe = store.subscribe((mutation) => {
      if (mutation.type === 'AUTH_LOGOUT' || mutation.type === 'AUTH_ERROR') {
        router.push('/login')
      }
    })
    return {
      isAuthenticated,
      authLogin,
      unsubscribe
    }
  }
}
</script>

<style lang="sass">
// Not remove
</style>
