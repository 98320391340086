<template lang="pug">
vue-easy-lightbox(
  :visible="visible"
  :imgs="imgs"
  :index="0"
  @hide="galleryToggle"
)
</template>

<script>
import { ref, watch } from 'vue'

export default {
  props: ['images'],
  setup: function (props) {
    const visible = ref(false)
    const imgs = ref([])
    const galleryToggle = () => {
      visible.value = !visible.value
    }
    watch(props, () => {
      const images = props.images.map(item => {
        return {
          src: `/assets/images/${item}`
        }
      })
      imgs.value = [...images]
      galleryToggle()
    })
    return {
      imgs, visible, galleryToggle
    }
  }
}
</script>
