const colors = (field) => {
  return [
    'match',
    ['get', field],
    'black', '#000',
    'blue', '#2648BE',
    'cyan', '#00E4D6',
    'darkgreen', '#00AB59',
    'green', '#9ED726',
    'purple', '#C92F8C',
    'red', '#FB4141',
    'yellow', '#FFFA7E',
    'brown', '#FF7D34',
    /* other */ '#fff'
  ]
}

export const getProjectLayers = () => {
  return {
    project: {
      legend: {
        name: 'Проект строительства',
        color: 'none'
      },
      options: {
        type: 'vector',
        tiles: [`${window.location.origin}/road-2021/vector/project-z20/{z}/{x}/{y}.pbf`],
        minzoom: 0,
        maxzoom: 20,
        bounds: [85.602146, 55.316510, 86.132034, 55.523452]
      },
      layers: [
        {
          id: 'p_substrate',
          type: 'fill',
          source: null,
          'source-layer': 'substrate',
          paint: {
            'fill-color': '#fff',
            'fill-opacity': 0.5
          }
        },
        {
          id: 'p_polygon',
          type: 'fill',
          source: null,
          'source-layer': 'polygon',
          paint: {
            'fill-color': colors('СЛОЙ'),
            'fill-opacity': 0.7
          }
        },
        {
          id: 'p_lines_dashed',
          type: 'line',
          source: null,
          filter: ['==', 'ТИПЛИ', 'dashed'],
          'source-layer': 'lines',
          paint: {
            'line-color': colors('СЛОЙ'),
            'line-dasharray': [3, 3],
            'line-width': ['get', 'ВЕСЛИ']
          }
        },
        {
          id: 'p_lines_dots',
          type: 'line',
          source: null,
          filter: ['==', 'ТИПЛИ', 'dots'],
          'source-layer': 'lines',
          paint: {
            'line-color': colors('СЛОЙ'),
            'line-dasharray': [1, 3],
            'line-width': ['get', 'ВЕСЛИ']
          }
        },
        {
          id: 'p_lines_longdashed',
          type: 'line',
          source: null,
          filter: ['==', 'ТИПЛИ', 'longdashed'],
          'source-layer': 'lines',
          paint: {
            'line-color': colors('СЛОЙ'),
            'line-dasharray': [6, 6],
            'line-width': ['get', 'ВЕСЛИ']
          }
        },
        {
          id: 'p_lines_shortdashed',
          type: 'line',
          source: null,
          filter: ['==', 'ТИПЛИ', 'shortdashed'],
          'source-layer': 'lines',
          paint: {
            'line-color': colors('СЛОЙ'),
            'line-dasharray': [4, 2],
            'line-width': ['get', 'ВЕСЛИ']
          }
        },
        {
          id: 'p_lines_continuous',
          type: 'line',
          source: null,
          'source-layer': 'lines',
          filter: ['==', 'ТИПЛИ', 'Continuous'],
          paint: {
            'line-color': colors('СЛОЙ'),
            'line-width': ['get', 'ВЕСЛИ']
          }
        },
        {
          id: 'p_points',
          type: 'symbol',
          source: null,
          'source-layer': 'points',
          paint: {
            'text-color': colors('Color'),
            'text-halo-color': 'rgba(255, 255, 255, 0.7)',
            'text-halo-width': 1
          },
          layout: {
            'text-field': '{TEXTSTRING}',
            'text-anchor': 'bottom-left',
            'text-justify': 'left',
            'text-max-width': 200,
            'text-size': ['*', ['get', 'zoom'], ['get', 'Size'], 0.3],
            'text-rotate': ['get', 'Angle']
          },
          minzoom: 16
        },
        {
          id: 'p_pk',
          type: 'symbol',
          source: null,
          'source-layer': 'pk',
          paint: {
            'text-color': '#000',
            'text-halo-color': 'rgba(255, 255, 255, 0.7)',
            'text-halo-width': 1
          },
          layout: {
            'text-field': '{PK}',
            'text-anchor': 'left',
            'text-size': ['*', ['get', 'zoom'], 2, 0.3],
            'text-radial-offset': 1,
            'icon-allow-overlap': true,
            'icon-image': 'pk',
            'icon-rotate': -90,
            'icon-size': [
              'interpolate',
              ['linear'],
              ['zoom'],
              10, 0.1,
              14, 0.3
            ]
          },
          minzoom: 12
        }
      ]
    }
  }
}
